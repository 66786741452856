.chat-wrap {
  width: 100%;
  height: calc(100vh - 55px);
  overflow-y: hidden;
  @media only screen and (max-width: 1200px) {
    height: calc(100vh - 50px);
  }
  .chat-module {
    width: 100%;
    height: calc(100vh - 55px);
    @media only screen and (max-width: 1200px) {
      height: calc(100vh - 50px);
    }
  }
}
