$pagination-primary-color: var(--main-blue);
$pagination-text-color: #454a52;
$pagination-border-color: #cecece;
$pagination-background-color: #fff;
$pagination-border-radius: 3px;
$pagination-padding: 5px 9px;
$pagination-margin: 0 5px;

.pagination {
  padding-bottom: 10px;
  padding-top: 10px;
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}

.paginationList {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.paginationItem {
  background: $pagination-background-color;
  border: 1px solid $pagination-border-color;
  border-radius: $pagination-border-radius;
  color: $pagination-text-color;
  cursor: pointer;
  font-size: 13px;
  margin: $pagination-margin;
  padding: $pagination-padding;
  transition: all 0.3s ease-in-out;
  user-select: none;
  vertical-align: middle;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &:not(.disabled):hover {
    background-color: #eff3f5;
    color: $pagination-text-color;
  }
  &.active {
    background-color: $pagination-primary-color;
    color: $pagination-background-color;
    cursor: default;
  }
  &.paginationGroup {
    content: '';
  }
  .icon {
    &::before {
      font-size: 11px;
    }
  }
}

.paginationSelect {
  position: relative;
  .list {
    background: $pagination-background-color;
    border: 1px solid $pagination-border-color;
    bottom: 110%;
    left: 50%;
    min-width: 32px;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s linear;
    z-index: -1;
    &.visible {
      opacity: 1;
      visibility: visible;
      z-index: 9;
    }
    .item {
      cursor: pointer;
      font-size: 13px;
      padding: $pagination-padding;
      &:not(.disabled):hover {
        background-color: rgba($pagination-primary-color, 0.9);
        color: $pagination-background-color;
      }
    }
  }
}
